// src/pages/ReviewAnalyze.js
import React, {useState, useEffect, useRef} from 'react';
import {
    Radio,
    Space, Row, Col, Tooltip, Textarea, MessagePlugin
} from 'tdesign-react';
import {
    startAnalysisApi,
    getAnalysisProgressApi,
    postTestAnalysisApi,
    stopAnalysisApi,
    getKvApi,
    createKvApi,
    updateKvApi,
} from '../api/api';

import AnalysisCommentTable from '../components/AnalysisCommentTable';
import AnalysisTaskList from '../components/AnalysisTaskList';
import AnalysisHeader from '../components/AnalysisHeader';
import ReviewReport from './ReviewReport';
import {postChatModel} from "../api/modelService";

const ReviewAnalyze = ({tasks, selectedTask, fetchTasks, SelectedReply, userSubscribeInfo}) => {

    // 基础状态管理
    const [taskList, setTaskList] = useState((() => {
        try {
            const storedData = localStorage.getItem('tasks');
            return storedData ? JSON.parse(storedData) : tasks;
        } catch (error) {
            console.error(`Error accessing localStorage:`, error);
            return tasks;
        }
    })());

    const [inputValues, setInputValues] = useState((() => {
        try {
            const storedData = localStorage.getItem('inputValues');
            return storedData ? JSON.parse(storedData) : {};
        } catch (error) {
            console.error(`Error accessing localStorage:`, error);
            return {};
        }
    })());

    const [currentTaskId, setCurrentTaskId] = useState(() => selectedTask?.task_id ?? JSON.parse(localStorage.getItem('selectedTask'))?.task_id ?? (taskList.length > 0 ? taskList[0].task_id : null));
    const [currentPlatform, setCurrentPlatform] = useState(selectedTask?.platform ?? null);
    const [currentKeyWord, setCurrentKeyword] = useState(selectedTask?.keyword ?? null);
    const [analysisState, setAnalysisState] = useState("initial");
    const [analysisAble, setAnalysisAble] = useState(true);
    const [activeMode, setActiveMode] = useState("1");
    const [analysisResult, setAnalysisResult] = useState("column");
    const [templateContent, setTemplateContent] = useState({content1: '', content2: ''});
    const [chartOptions, setChartOptions] = useState({});
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [PieData, setPieData] = useState();
    const [pieDataLoading, setPieDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(false);
    const platform = useRef('');

    const platformMap = {
        dy: '抖音',
        xhs: '小红书'
    };

    const [allComments, setAllComments] = useState([]);

    // 更新当前进度状态
    const updateProgress = async (taskId) => {
        if (!taskId) return;

        try {
            const data = await getAnalysisProgressApi(taskId);
            if (data.msg === "success") {
                // console.log('Progress:', data.data);
                if(data.data.state===1){
                    setAnalysisState("initial");
                }
                else if(data.data.state===2){
                    setAnalysisState("running");
                }
                else{
                    setAnalysisState("finish");
                }
                setCommentsTotal(data.data.sum);
                if (data.data.sum !== 0) {
                    const percentage = parseFloat(((data.data.num / data.data.sum) * 100).toFixed(2));
                    if (percentage === 100) {
                        setAnalysisState("finish");
                        await fetchTasks();
                        setTaskList(JSON.parse(localStorage.getItem('tasks')));
                    }
                }
            }
        } catch (error) {
            console.error('Failed to update progress:', error);
        }
    };

    // 处理输入改变
    const handleInputChange = (taskId, value) => {
        setInputValues(prev => {
            const newValues = {...prev, [taskId]: value};
            localStorage.setItem("inputValues", JSON.stringify(newValues));
            return newValues;
        });
    };

    // 更新模板内容
    const updateTemplateContent = async (taskId) => {
        const jsonValue = {
            content1: templateContent.content1,
            content2: templateContent.content2
        };

        const back_data = {
            task_id: taskId,
            json_key: "template",
            json_value: JSON.stringify(jsonValue)
        };

        try {
            const response = await getKvApi(taskId, "template");
            if (response.code === 200) {
                if (response.data.length === 0) {
                    await createKvApi(back_data);
                } else {
                    await updateKvApi(back_data);
                }
            }
        } catch (error) {
            console.error('Failed to update template:', error);
        }
    };

    // 开始分析
    const startAnalyze = async (taskId, commentId = null) => {
        await updateTemplateContent(taskId);
        setAnalysisAble(false);

        const storedTemplate = localStorage.getItem(`${currentTaskId}-template`);
        const currentTemplate = storedTemplate ? JSON.parse(storedTemplate) : templateContent;
        const keyword = inputValues[taskId] || currentKeyWord;

        const analysisRequest = activeMode === "1" ?
            `背景：
            ${currentTemplate.content1 ? "我们的服务介绍是：" + currentTemplate.content1 : ""}
            ${currentTemplate.content2 ? "我想要的客户描述：" + currentTemplate.content2 : ""}
            
            你要分析抖音以及小红书平台上关于《${keyword}》搜索后呈现相关内容下方的评论，内容都是跟关键词对应相关的
            你的任务：
            1. 分析关键词背后对应的产品、服务、行业
            2. 判断后面提供的评论是否是对这个关键词对应的营销视频/笔记内容感兴趣的客户
            3. 对这个客户进行评级：
            可能有意向：不限于想了解我们的服务价格、或想学习、合作、加盟、购买、聊天相关产品/服务/公司，对产品/服务有疑问或比较纠结的客户
            无意向：对产品/服务无意向或批评的客户以及提供同样产品/服务的同行直接意向判定为无意向` :
            `背景：现在要帮助${keyword}相关的公司来分析用户在${currentPlatform}平台上关于${keyword}相关关键词内容下面的评论
            任务：分析这个评论的用户是否已经或可能成为相关服务的客户，并且分析评论中用户的情绪、提及相关产品/服务的情况、对相关产品/服务的满意点、不满点、疑问点`;

        const outputFields = activeMode === "1" ? [
            {
                key: "意向客户",
                explanation: `用户是否为有明确意向了解/购买${keyword}这个产品或服务相关信息的客户（可能有意向填是、无意向填否)`
            },
            {
                key: "分析理由",
                explanation: "20字内简要说明这么分析的理由"
            }
        ] : [
            {
                key: "意向客户",
                explanation: `可能有意向：不限于想了解我们的服务价格、或想学习、合作、加盟、购买、聊天相关产品/服务/公司，对产品/服务有疑问或比较纠结的客户，填是
                无意向：对产品/服务无意向或批评的客户以及提供同样产品/服务的同行直接意向判定为无意向，填否`
            },
            {
                key: "情绪分析",
                explanation: "分析用户的情绪，填写选项：正向、负向、中性"
            },
            {
                key: "提及产品",
                explanation: `用户这条评论是否是围绕着${keyword}相关的产品/品牌或服务进行讨论（是、否、不确定）`
            },
            {
                key: "满意点",
                explanation: "评论中对本产品/品牌/服务的满意点，没有就填写没有"
            },
            {
                key: "不满点",
                explanation: "评论中对本产品/品牌/服务的不满点，没有就填写没有"
            },
            {
                key: "疑问点",
                explanation: "评论中对本产品/品牌/服务的疑问点，没有就填写没有"
            }
        ];

        try {
            const raw = {
                comment_id: commentId,
                task_id: taskId,
                platform: platform.current,
                analysis_request: analysisRequest,
                output_fields: outputFields
            };

            if (commentId) {
                await postTestAnalysisApi(raw);
            } else {
                await startAnalysisApi(raw);
            }

            await updateProgress(taskId);
            setAnalysisAble(true);
            setAnalysisState("running");
        } catch (error) {
            console.error('Failed to start analysis:', error);
            MessagePlugin.error('开始分析失败');
            setAnalysisAble(true);
        }
    };

    // 停止分析
    const stopAnalyze = async (taskId) => {
        setAnalysisAble(false);
        try {
            await stopAnalysisApi({task_id: taskId});
            await updateProgress(taskId);
        } catch (error) {
            console.error('Failed to stop analysis:', error);
            MessagePlugin.error('停止分析失败');
        } finally {
            setTimeout(() => setAnalysisAble(true), 2000);
        }
    };

    // 处理分析操作
    const handleAnalysis = async (taskId, commentId = null) => {
        if (analysisState === 'initial') {
            await startAnalyze(taskId, commentId);
        } else if (analysisState === 'running') {
            await stopAnalyze(taskId);
        }
        await fetchTasks();
        setTaskList(JSON.parse(localStorage.getItem('tasks')));
    };

    // 处理任务点击
    const handleTaskClick = (item) => {
        setCurrentTaskId(item.task_id);
        setCurrentPlatform(item.platform);
        setCurrentKeyword(item.keyword);
        setAnalysisState(item.analysis_state);
        localStorage.setItem('selectedTask', JSON.stringify(item));
        updateProgress(item.task_id);
    };

    // 处理模板输入改变
    const handleInputChangeT1 = (value) => {
        setTemplateContent(prev => {
            const newTemplate = {...prev, content1: value};
            localStorage.setItem(`${currentTaskId}-template`, JSON.stringify(newTemplate));
            return newTemplate;
        });
    };

    const handleInputChangeT2 = (value) => {
        setTemplateContent(prev => {
            const newTemplate = {...prev, content2: value};
            localStorage.setItem(`${currentTaskId}-template`, JSON.stringify(newTemplate));
            return newTemplate;
        });
    };

    const handleGotoReply = () => {
        if (currentTaskId) {
            SelectedReply("c", currentTaskId);
        } else {
            MessagePlugin.error('任务ID不存在');
        }
    };

    // 初始加载模板数据
    useEffect(() => {
        const loadTemplateData = async () => {
            if (!currentTaskId || !currentKeyWord) return;

            try {
                const storedData = localStorage.getItem(`${currentTaskId}-template`);
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    if ('content1' in parsedData && 'content2' in parsedData) {
                        setTemplateContent(parsedData);
                        return;
                    }
                }

                setLoading(true);
                const data = await postChatModel(currentKeyWord, "template");
                if (data.code === 0) {
                    const jsonData = JSON.parse(data.messages[0].content);
                    const newTemplate = {
                        content1: jsonData['我提供的服务介绍'],
                        content2: jsonData['我想要的客户描述']
                    };
                    setTemplateContent(newTemplate);
                    localStorage.setItem(`${currentTaskId}-template`, JSON.stringify(newTemplate));
                }
            } catch (error) {
                console.error('Error loading template:', error);
                MessagePlugin.error("模板加载失败");
            } finally {
                setLoading(false);
            }
        };

        loadTemplateData();
        updateProgress(currentTaskId);
    }, [currentTaskId, currentKeyWord]);

    // 更新平台引用
    useEffect(() => {
        platform.current = currentPlatform;
    }, [currentPlatform]);

    // 定时更新进度
    useEffect(() => {
        if (analysisState === "running") {
            const intervalId = setInterval(() => updateProgress(currentTaskId), 5000);
            return () => clearInterval(intervalId);
        }
    }, [currentTaskId, analysisState]);

    // 处理报表数据
    useEffect(() => {
        if (activeMode === '2' && analysisState === 'finish') {
            const PieDataList = localStorage.getItem(`${currentTaskId}-PieData`);
            if (PieDataList) {
                setPieData(JSON.parse(PieDataList));
            } else {
                setPieDataLoading(true);
            }
        }
    }, [activeMode, analysisState, currentTaskId]);

    useEffect(() => {
        if (chartOptions.title === "emotion") {
            setAnalysisResult("column");
        }
    }, [chartOptions]);

    return (
        <div style={{display: 'flex', width: "100%"}}>
            <AnalysisTaskList
                mode="analyze"
                taskList={taskList}
                currentTaskId={currentTaskId}
                onTaskClick={handleTaskClick}
                platformMap={platformMap}
            />
            <Space direction="vertical" style={{
                marginLeft: "10px",
                height: "94vh",
                overflowX: "hidden",  // 隐藏水平滚动条
                overflowY: "auto",
                paddingLeft: "5px",
            }}>
                <AnalysisHeader
                    commentsTotal={commentsTotal}
                    currentKeyWord={currentKeyWord}
                    currentPlatform={currentPlatform}
                    inputValues={inputValues}
                    currentTaskId={currentTaskId}
                    handleInputChange={handleInputChange}
                    progress={progress}
                    handleAnalysis={handleAnalysis}
                    startAnalyze={startAnalyze}
                    analysisState={analysisState}
                    analysisAble={analysisAble}
                    activeMode={activeMode}
                    taskList={taskList}
                    setTaskList={setTaskList}
                    SelectedReply={handleGotoReply}  // 传递处理函数
                />
                <Space style={{marginBottom: '10px'}}>
                    <Space direction='vertical'>
                        <div>我的服务介绍</div>
                        <Textarea
                            placeholder="例如：我是一家美容机构，提供美容服务"
                            autosize={{minRows: 3}}
                            value={templateContent.content1}
                            onChange={handleInputChangeT1}
                            style={{marginBottom: '10px', width: '400px'}}
                            disabled={analysisState === 'finish'}
                        />
                    </Space>
                    <Space direction='vertical'>
                        <div>我想要的客户</div>
                        <Textarea
                            placeholder="例如：我想要有美容需求的客户"
                            autosize={{minRows: 3}}
                            value={templateContent.content2}
                            onChange={handleInputChangeT2}
                            style={{marginBottom: '10px', width: '400px'}}
                            disabled={analysisState === 'finish'}
                        />
                    </Space>
                </Space>
                <Row>
                    {analysisState === 'finish' && activeMode === '2' && (
                        <Col flex="none">
                            <Radio.Group
                                style={{marginRight: "15px"}}
                                size="medium"
                                value={analysisResult}
                                onChange={(v) => setAnalysisResult(v)}
                                variant='primary-filled'
                            >
                                <Radio.Button value="column" className="custom-radio-button">分析详情</Radio.Button>
                                <Radio.Button value="report" className="custom-radio-button">分析报告</Radio.Button>
                            </Radio.Group>
                        </Col>
                    )}
                    {analysisState === 'initial' && (
                        <Col>
                            <Radio.Group
                                style={{marginRight: "15px"}}
                                size="medium"
                                value={activeMode}
                                disabled={progress > 0}
                                onChange={(v) => setActiveMode(v)}
                                variant='primary-filled'
                            >
                                <Tooltip content="只分析筛选意向客户" destroyOnClose showArrow theme="default">
                                    <Radio.Button value="1" className="custom-radio-button">获客模式</Radio.Button>
                                </Tooltip>
                                <Tooltip content="分析用户对品牌的情绪、满意度等信息" destroyOnClose showArrow
                                         theme="default">
                                    <Radio.Button value="2" className="custom-radio-button">品牌分析</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        </Col>
                    )}
                </Row>
                {analysisResult === 'column' && <AnalysisCommentTable
                    taskId={currentTaskId}
                    platform={platform}
                    userSubscribeInfo={userSubscribeInfo}
                    activeMode={activeMode}
                    setAllComments={setAllComments}
                />
                }
                {analysisResult === 'report' &&
                    <ReviewReport taskId={currentTaskId} commentList={allComments} commentsTotal={commentsTotal}
                                  PieData={PieData}
                                  pieDataLoading={pieDataLoading}
                                  setChartOptions={setChartOptions}
                    />
                }
            </Space>
        </div>
    );
};

export default ReviewAnalyze;
