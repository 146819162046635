import React, {useEffect} from 'react';
import {Input, InputAdornment, Button, Row, Space, MessagePlugin} from 'tdesign-react';
import dyIcon from "../public/dy.svg";
import xhsIcon from "../public/xhs.svg";
import {getTaskIdAnalysisResultApi} from "../api/api";

const AnalysisHeader = ({
                            commentsTotal,
                            currentKeyWord,
                            currentPlatform,
                            inputValues,
                            currentTaskId,
                            handleInputChange,
                            progress,
                            handleAnalysis,
                            analysisState,
                            analysisAble,
                            activeMode,
                            taskList,
                            setTaskList,
                            SelectedReply
                        }) => {
    const handleButtonClick = () => {
        if (analysisState === "finish") {
            if (activeMode === '2') {
                handleDownload();
            } else {
                if (typeof SelectedReply === 'function') {
                    SelectedReply();
                } else {
                    MessagePlugin.error('私信功能暂不可用');
                }
            }
        } else {
            handleAnalysis(currentTaskId);
        }
    };

    const handleDownload = () => {
        getTaskIdAnalysisResultApi(currentTaskId).then(data => {
            if (data.data.url) {
                window.open(data.data.url, '_blank');
            }
        });
    };

    const platformIconMap = {
        'dy': <img src={dyIcon} alt="Prefix Icon" style={{width: '24px', marginTop: '16px'}}/>,
        'xhs': <img src={xhsIcon} alt="Prefix Icon" style={{width: '24px', marginTop: '16px'}}/>
    };

    useEffect(() => {
        const changeTaskList = taskList.map((item) => {
            if (item.task_id === currentTaskId) {
                return {
                    ...item,
                    analysis_state: analysisState
                }
            }
            return item;
        });
        setTaskList(changeTaskList);
    }, [analysisState]);

    const getButtonText = () => {
        if (!analysisAble) return '操作中';
        if (analysisState === "finish") {
            return activeMode === '2' ? '下载结果' : '前往私信';
        }
        return analysisState === 'initial' ? '开始分析' : '停止分析';
    };

    return (
        <Row style={{width: "100%"}}>
            <Space align={"center"}>
                {commentsTotal === 0 ? (
                    <h2>暂未收集评论</h2>
                ) : (
                    <Row>
                        {platformIconMap[currentPlatform]}<h2>&nbsp;{currentKeyWord}&nbsp;{commentsTotal}条</h2>
                    </Row>
                )}
                <InputAdornment prepend="我的产品/服务/品牌是" style={{width: "420px", marginLeft: "10px"}}>
                    <Input
                        placeholder="请输入"
                        value={inputValues[currentTaskId] || ''}
                        onChange={(value) => handleInputChange(currentTaskId, value)}
                    />
                </InputAdornment>
                <Button
                    style={{marginLeft: "10px"}}
                    onClick={handleButtonClick}
                    disabled={!analysisAble || commentsTotal === 0}
                >
                    {getButtonText()}
                </Button>
            </Space>
        </Row>
    );
};

export default AnalysisHeader;